.textarea_box {
    position: relative;
    width: 100%;
}

.custom_textarea {
    /* width: 100%;
    height: 100% !important;
    min-height: 48px !important;
    max-height: 100% !important;
    padding-right: 40px;
    overflow-y: auto */
    /* min-height: 48px !important; */
    min-height: 108px !important;
    padding: 10px 35px 46px 10px;
    font-size: 16px;
    border: 1px solid #c3c7cf;
    /* background-color: #f9f9f9; */
    border-radius: 6px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.custom_textarea:hover,
.custom_textarea:focus-within,
.custom_textarea:focus {
    border-color: #17a8b5;
}

/* 自定义滚动条 */
.custom_textarea::-webkit-scrollbar {/*滚动条整体样式*/
    -webkit-appearance: none;
    width: 5px;
    height: 5px;
}

.custom_textarea::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    cursor: pointer;
    border-radius: 5px;
    background: #e9e9e9;
    -webkit-transition: color .2s ease;
    transition: color .2s ease;
}

.custom_textarea::-webkit-scrollbar-thumb:hover {
    background: #e3e3e3;
    cursor: default;
}

.send_butn_img {
    position: absolute;
    right: 10px;
    top: 15px;
    cursor: pointer;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.thinking_loading_box {
    width: 32px;
    height: 32px;
    position: absolute;
    right: 9px;
    top: 9px;
    cursor: pointer;
    border-radius: 100%;
    user-select: none;
}

.circle_img {
    width: 32px;
    height: 32px;
    position: absolute;
    right: 0px;
    top: 0px;
    animation: spin 2s linear infinite;
}

.square_img {
    width: 12px;
    height: 12px;
    position: absolute;
    right: 10px;
    top: 10px;
    background: url('../../../../statics/thinking_square.png') no-repeat center / 100% 100%;
    transition: background 0.5s, box-shadow 0.5s;
}


/* 隐藏伪元素用来预加载悬停背景图 */
.thinking_loading_box::before .square_img {
    content: "";
    background: url('../../../../statics/thinking_square_hover.png') no-repeat center / 100% 100%;
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
}

.thinking_loading_box::after .square_img {
    content: "";
    background: url('../../../../statics/thinking_square_active.png') no-repeat center / 100% 100%;
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
}

.thinking_loading_box:hover .square_img {
    background: url('../../../../statics/thinking_square_hover.png') no-repeat center / 100% 100%;
}

.thinking_loading_box:active .square_img {
    background: url('../../../../statics/thinking_square_active.png') no-repeat center / 100% 100%;
}

.reasoning_box {
    position: absolute;
    left: 10px;
    bottom: 10px;
    display: flex;
}

.reasoning {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 118px;
    height: 36px;
    border: 1px solid #c1c1c1;
    margin-right: 16px;
    border-radius: 36px;
    background-color: #f9f9f9;
    user-select: none;
    cursor: pointer;
}

.reasoning:hover {
    background-color: #e4f0f2;
}

.reasoning_active {
    border: 1px solid #17a8b5;
    color: #17a8b5;
    background-color: #e4f0f2;
}

.reasoning_img {
    margin-right: 2px;
}

.reasoning_text {
    font-size: 16px;
    line-height: 24px;
}